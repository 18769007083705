import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import ArticleCards from '../../../components/ArticleCards'
import NoResultsText from '../../../components/NoResultsText'

const Foundation = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle(
        filter: { category: { title: { eq: "Foundation" } } }
        sort: { fields: [featured, publishDate], order: [DESC, DESC] }
      ) {
        edges {
          node {
            ...ArticlesQuery
          }
        }
        totalCount
      }
      allContentfulAsset(
          filter: { description: { eq: "#foundation-category-banner" } }
        ) {
        edges {
          node {
            fixed(width: 250, quality: 90) {
                ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  `)

  const articles = data.allContentfulArticle.edges
  const foundationImage = data.allContentfulAsset.edges[0].node

  const FoundationBanner = styled.div`
    border: none;
    background-color: #fff;
    padding: 10px 0 20px;

    p {
      text-align: center;
    }

    img {
      margin: 20px auto;
      float: none;
    }

    .foundation-banner-btn {
      text-align: center;
    }

    .foundation-banner-messaging {
      max-width: 250px;
      margin: auto;
      font-size: 20px;
    }

    @media (min-width: 480px) {
      height: 274px;
      padding: 0;

      p {
        text-align: left;
        vertical-align: top;
      }

      img {
        margin: 0 15px 0 0;
        float: left;
      }

      .foundation-banner-messaging {
        max-width: inherit;
        padding: 15px 10px 0;
        line-height: 22px;
        font-size: 20px;
      }

      .foundation-banner-btn {
        text-align: left;
      }

      @media (min-width: ${props => props.theme.responsive.large}) {
        .foundation-banner-messaging {
          padding: 20px 10px 0;
          line-height: 27px;
          font-size: 24px;
        }
      }
    }
`
  return (
    <>
      <section style={{ padding: '0 0 75px', margin: '0' }}>
        <FoundationBanner>
          <img style={{ maxWidth: foundationImage.fixed.width }} src={foundationImage.fixed.src} alt="" />
          <p class="foundation-banner-messaging">
            Find out how to get involved in Seint's nonprofit efforts
          </p>
          <p class="foundation-banner-btn">
            <a href="/foundation/" className="btn--pill">
              <span>Learn More</span>
            </a>
          </p>
        </FoundationBanner>
      </section>
      <section>
        <h2>Foundation News</h2>
        {articles.length ? (
          <ArticleCards articles={articles} />
        ) : (
          <NoResultsText>We haven't added any 'Foundation' news yet 😢</NoResultsText>
        )}
      </section>
    </>
  )
}

export default Foundation
